<template>
  <div>
    <loading-overlay
      :closeOnBackdrop="false"
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      size="lg"
      color="dark"
      :title=title
      :show.sync="modalActive"
      @update:show="closeModal()"
    >
      <CCol sm="12" class="my-2">
          <vue-dropzone 
              ref="myVueDropzone" 
              id="dropzone" 
              :options="dropzoneOptions" 
              :useCustomSlot="true"
              v-on:vdropzone-files-added="sendingEvent"
              v-on:vdropzone-removed-file="deleteFile"
              v-on:vdropzone-error="DropzoneError"
          > 
              <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                  <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
              </div>
          </vue-dropzone>
      </CCol>
      <CRow class="m-0">
        <CCol  sm="12" lg="12" class="d-flex justify-content-end">
          <CButton
            color="add"
            size="sm"
            square
            @click="submitFiles"
            :disabled="isSubmit" 
          >
            <CIcon name="checkAlt"/>
          </CButton>
        </CCol>
      </CRow>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="itemsDocs"
          :fields="fieldsDoc"
          column-filter
          :items-per-page="5"
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          hover
          small
          sorter
          pagination
        >
          <template #DocsRoute="{item}">
            <td class="py-2 text-center">
              <CButton
                class="btn btn-sm btn-watch mr-1"
                color="excel"
                v-c-tooltip="{placement:'top-end',content:$t('label.download')+' '+$t('label.document')}"
                target="_blank"
                :href="item.DocsRoute"
              >
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButton
                class="btn btn-sm btn-wipe"
                v-c-tooltip="{
                  content: $t('label.delete')+$t('label.document'),
                  placement: 'top',
                }"
                @click="deleteDocument(item)"
              >
                <CIcon name="cil-trash" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
      <template #footer>
        <CButton color="wipe" @click="closeModal()">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { DateFormater, alertPropertiesHelpers } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

let user = JSON.parse(localStorage.getItem('user'));


//DATA
function data() {
  return {
    itemsDocs: [],
    modalActive: false,
    Loading: false,
    StandardDocumentId: '',
    StandardDocumentDs:'',
    StandardDocumentName: '',
    StandardDocumentDescription: '',
    StandardOrganization: '',
    ShippingLineId: '',
    Status: '1',
    docFile: null,
    uploadPercent: 0,
    responsePercent: 0,
    isSubmit: false,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
          this.removeAllFiles();
          this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    }
  };
}

//METHOD
function listarDocs(id) {
  this.itemsDocs = [];
  this.Loading = true;
  let listado = [];
  let listado2 = [];
  this.$http
    .get("StandardDocument-by-id", { StandardDocumentId: id })
    .then((response) => {
      listado2 = [...response.data.data];
      listado = listado2[0].StandardDocumentDocJson;
      let Nro = 1;
      
      this.itemsDocs = listado.map((listado) =>
          Object.assign({}, this.itemsDocs, {
            Nro: Nro++,
            StandardDocumentDocId: listado.StandardDocumentDocId,
            StandardDocumentDocRoute: listado.StandardDocumentDocRoute.replace(
                  "StandarDocument/",
                  "",
                  "i"
                ),
            DocsRoute: listado.StandardDocumentDocRoute
              ? `${
                  this.$store.getters["connection/getBase"]
                }${listado.StandardDocumentDocRoute.replace("Public/", "", "i")}`
              : "N/A",
            StandardDocumentDs: listado.StandardDocumentDs,
            Usuario: listado2[0].TransaLogin ? listado2[0].TransaLogin : "N/A",
            Fecha: listado2[0].TransaRegDate
              ? DateFormater.formatOnlyDateWithSlash(listado2[0].TransaRegDate)
              : "N/A",
            FgActStandardDocumentDoc: listado.FgActStandardDocumentDoc ? 1:0,
          })
        
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.Loading = false;
    });
  }

function sendingEvent(files){
  //this.$refs.myVueDropzone.removeFile(files);
  if(files[0].name) this.docFile = files[0];
}
function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function deleteDocument(item) {
    this.$swal.fire(
      alertPropertiesHelpers(this, {
        text:  `${this.$t('label.changeStatusQuestion')} ?`,
      })
    ).then((result) => {
      if (result.isConfirmed) {
        this.Loading = true;
        let StandardDocumentDocJson = {
          StandardDocumentDocId: item.StandardDocumentDocId,
          FgActStandardDocumentDoc: 0,
        }

        this.$http
          .ejecutar("POST", "StandardDocumentDoc-Deactivate", StandardDocumentDocJson, {
            root: "StandardDocumentDocJson",
          })
          .then((response) => {
            let res = [...response.data.data];
            this.$notify({
              group: "container",
              title: "¡Exito!",
              text: res[0].Response,
              type: "success",
            });
            this.Loading = false;
            this.listarDocs(this.StandardDocumentId);
          })
        .catch((err) => {
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
          this.Loading = false;
        });
      }
    }); 
}

async function submitFiles() {
  this.isSudmit = true;
  this.Loading = true;
  if(this.docFile!=null){
    let formData = new FormData();
    formData.append('StandardDocumentId', this.StandardDocumentId);
    formData.append('File', this.docFile);
    
    this.$http.post("StandardDocument-insert", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}files/`,
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this),
        onDownloadProgress: function( progressEvent ) {
          this.responsePercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)
    }).then((response) => {
      let res = response.data.data.files[0].path;
      
      let StandardDocumentDocJson = {
        StandardDocumentDocRoute: res
      }
      let StandardDocumentJson = {
        StandardDocumentId: this.StandardDocumentId,
        StandardDocumentName: this.StandardDocumentName,
        StandardDocumentDs: this.StandardDocumentDs,
        StandardDocumentDescription: this.StandardDocumentDescription,
        StandardOrganization: this.StandardOrganization,
        ShippingLineId: this.ShippingLineId.value,
        Status: this.Status,
        StandardDocumentDocJson: StandardDocumentDocJson
      };
    
      //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
      this.$http
        .ejecutar("PUT", "StandardDocument-update", StandardDocumentJson, {
          root: "StandardDocumentJson",
        })
        .then((response) => {
          res = [...response.data.data];
          this.$notify({
            group: "container",
            title: "¡Exito!",
            text: res[0].Response,
            type: "success",
          });
          this.Loading = false;  
          this.isSudmit = false;   
          this.listarDocs(this.StandardDocumentId);
           this.Refresh();  
        })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
        this.Loading = false;
        this.isSudmit = false;
      });
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.Loading = false;
      this.isSudmit = false;
    });
  }else{
    this.isSudmit = false;
    this.Loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.documentQuestion'),
      type: "error"
    });
  }
}

function Refresh() {
  let files = this.$refs.myVueDropzone.getQueuedFiles();
  if(files.length != 0){
    this.$refs.myVueDropzone.removeFile(files[0]);
  }
  //this.StandardDocumentId= '';
  //this.ShippingLineId = '';
  //this.StandardDocumentDs='';
  //this.StandardDocumentName= '';
  //this.Status = 0;
}

function closeModal() {
  this.Refresh();
  this.$emit('close');
}

//COMPUTED
function fieldsDoc(){
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%; text-align:center;",
      filter: false,
    },
    { key: "StandardDocumentDs", label: this.$t('label.document'), _style: 'width:50%', _classes: 'text-uppercase text-center center-cell'},
    { key: "Usuario", label: this.$t('label.user'), _style: 'width:15%', _classes: 'text-uppercase text-center center-cell' },
    { key: "Fecha", label: this.$t('label.date'), _style: 'width:15%', _classes: 'text-center center-cell' },
    {
      key: "DocsRoute",
      label: "",
      sorter: false,
      filter: false,
      _style: "min-width:100px; text-align:center;",
    },
  ];
}



function isDisabled() {
  return this.docFile==null;
}


export default {
  name: "list-documents",
  mixins: [General],
  data,
  components: {
    vueDropzone: vue2Dropzone
  },
  props: { modal: Boolean,  documentItem: Object, title: String,},
  methods: {
    listarDocs,
    sendingEvent,
    submitFiles,
    deleteFile,
    DropzoneError,
    deleteDocument,
    Refresh,
    closeModal
  },
  computed:{
    fieldsDoc,
    isDisabled
  },
  watch: {
    modal: function(val) {
      if (val) {
        this.modalActive = true;
        this.StandardDocumentId = this.documentItem.StandardDocumentId;
        this.StandardDocumentName = this.documentItem.Name;
        this.StandardDocumentDescription = this.documentItem.StandardDocumentDescription;
        this.StandardOrganization = this.documentItem.StandardOrganization;
        this.ShippingLineId = this.documentItem.ShippingLineId;
        this.Status = this.documentItem.Status == "ACTIVO" ? 1 : 0;
        this.listarDocs(this.StandardDocumentId);
      }else{
        this.modalActive = false;
      }
    },
  },
};
</script>
<style scoped></style>
